body {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.scrollFadeInUp {
    opacity: 0; position: relative; top: 50px;
}

img {
	max-width: 100%;
	height: auto;
}

.table-striped tbody tr:nth-of-type(odd) {
	background: $table-dark-accent-bg;
}
