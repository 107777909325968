$blue:    #007BA3;

$line-height-lg:         1.43;
$line-height-sm:         1.22;

$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-sans-serif-condensed: $font-family-sans-serif;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;

$small-font-size: 70%;

$headings-font-family:   $font-family-sans-serif;
$headings-font-weight:   $font-weight-semi-bold;
$headings-line-height:   1.33;
$headings-color:       $blue;
$headings-margin-bottom: 1.1rem;
$enable-shadows: true;

$table-dark-bg: $blue;
$table-dark-border-color: $table-dark-bg;
$table-border-color: $table-dark-bg;
$table-dark-accent-bg: #dcebf1;
$table-dark-hover-bg: $table-dark-accent-bg;
$table-dark-color: #FFF;
